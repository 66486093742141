import './IndexCountdown'
import { headerController } from './HeaderController'

function onBgColorsLoaded() {
  document.getElementById('bg-colors-main').classList.add('bg-colors-loaded')
}

const ENABLE_CLASS = 'nav-sm-enabled'

document.addEventListener('DOMContentLoaded', function () {
  const logo = document.getElementById('logo')
  const navUl = document.querySelector('nav > ul')
  const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        logo.style.visibility = 'hidden'
        const b = navUl.classList.contains(ENABLE_CLASS)
        navUl.classList.remove(ENABLE_CLASS)
        if (b) headerController.buildFixedElementsAndDarkSections()
      } else {
        logo.style.visibility = 'visible'
        const b = navUl.classList.contains(ENABLE_CLASS)
        navUl.classList.add(ENABLE_CLASS)
        if (!b) headerController.buildFixedElementsAndDarkSections()
      }
    })
  })
  observer.observe(document.getElementById('section-main'))

  //display bg_colors after the top cutout has been loaded
  const image = document.getElementById('bg-colors-trigger')
  if (image.complete) {
    onBgColorsLoaded()
  } else {
    image.addEventListener('load', onBgColorsLoaded)
  }
})
